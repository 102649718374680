<template>
  <div class="container page">
    <van-nav-bar :title="this.$t('填写收款卡')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main-box">
      <div class="label">{{$t('请输入您的收款卡信息')}}</div>
      <van-cell-group>
        <van-field v-model="bank" :label="this.$t('銀行の名前')" type="text" :placeholder="this.$t('銀行の名前')" />
		<van-field v-model="bank_son_name" :label="this.$t('支店名')" type="text" :placeholder="this.$t('支店名')" />
		<van-field v-model="bank_son_hao" :label="this.$t('支店番号')" type="text" :placeholder="this.$t('支店番号')" />
		<van-field v-model="bankid" :label="this.$t('アカウント番号')" type="text" :placeholder="this.$t('アカウント番号')" />
		<van-field v-model="truename" :label="this.$t('アカウント名')" type="text" :placeholder="this.$t('アカウント名')" />
      </van-cell-group>
      <p>{{$t('親愛なるユーザー、あなたの資金の安全を確保するために、あなたの本当の名前と引き出しパスワードを設定してください。もし名前と口座名義が一致しない場合、引き出しができません。')}}</p>
    </div>
    <van-button class="bindCard" type="default" @click="bindCard()">{{$t('确认绑卡')}}</van-button>
    <van-popup v-model="showBank" round position="bottom" :style="{ height: '35%' }" >
      <van-picker
          show-toolbar
          :columns="banks"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
	data() {
		return {
			banks: [],
			showBank:false,
			userInfo:{},
			bank:"",
			bank_son_name: "",
			bank_son_hao: "",
			bankid: "",
			truename:"",
		};
	},
  methods: {
    back(){
		this.$router.back(-1)
		// return window.history.back();
    },
    bindCard(){
		if(this.userInfo.bankid){
			this.$toast(this.$t('请输入银行卡号'));
			return true;
		}
		// if(this.bank === "" || this.bank === null || this.bank === undefined){
		//   this.$toast.fail("请选择银行！");
		//   return false;
		// }
		this.$http({
			method: 'post',
			data:{bank:this.bank, bank_son_name:this.bank_son_name, bank_son_hao:this.bank_son_hao, bankid:this.bankid, truename:this.truename},
			url: 'user_set_bank'
		}).then(res=>{
			if(res.code === 200){
				this.$router.push({path:'/Mine'})
				this.$toast(res.msg);
			}else if(res.code ===401){
				this.$toast(res.msg);
			}
		})
    },
    showSelectBanks(){
      this.showBank = true;
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getBankList(){
      this.$http({
        method: 'get',
        url: 'sys_get_banks'
      }).then(res=>{
        if(res.code === 200){
          this.banks = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    onConfirm(value) {
      this.bank = value.text
      this.showBank = false;
    },
    onCancel() {
      this.showBank = false;
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getBankList();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 32px;
  line-height: 80px;
}
.van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.bankbox{
  padding: 15px;
  color: #000;
  background-color: #fff;
}
.bankbox .title{
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}
.main-box{
  background: #fff;

}
.main-box .label{
  padding: 20px;
  font-size: 35px;
  color: #797878;
}
::v-deep .van-picker__toolbar {
  height: 50px;
}
::v-deep .van-picker__cancel, .van-picker__confirm {
  padding: 0 20px;
  font-size: 20px;
}
::v-deep .van-picker-column {
  font-size: 40px;
}
.main-box p{
  padding: 0 20px;
  font-size: 30px;
  color: #ee0a24;
}
.bindCard {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      90deg,#e6c3a1,#7e5678);
}
</style>
