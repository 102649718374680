<template>
	<div class="page">
		<!-- <van-nav-bar title="选妃" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar> -->
		
		<van-icon style="
    position: fixed;
    left: 4vw;
    top: 6vw;
    color: rgb(255, 255, 255);
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 5px;" name="arrow-left" size="1.6rem" color="#fff" @click="back()" />
		
		<van-swipe :autoplay="3000" height="500">
		<van-swipe-item v-for="(image, index) in xuanfeidata.img_url" :key="index" width="100%">
			<van-image fit="cover" v-lazy="image" width="100%" height="100%" :src="image" />
		</van-swipe-item>
		</van-swipe>
		
		<div class="mm">
			<van-notice-bar
			class="notice-swipe"
			background="rgb(40, 40, 40)"
			color="rgb(249, 174, 61)"
			left-icon="volume-o"
			:text="$t('美女系统公告')"
			/>
			
			<div class="uinfo">
				<div class="hang white">{{$t('编号')}}：{{xuanfeidata.id}}</div>
				<div class="hang white">{{$t('激活可见')}}</div>
			</div>
			
			<div class="border kuangkuang mt10">
				<div class="ad flex-row score_">
					<div class="flex-column center" style="border-right: 1px solid #f6d5a1;width: 38%;margin-bottom: 2.5vw;">
						<div class="btitle">{{$t('邪恶天使')}}{{$t('评分')}}</div>
						<div class="deifen huang">{{xuanfeidata.score}}</div>
						<div class="fulipinpai white">{{$t('美丽女人的脸的价值')}}</div>
					</div>
					<div class="white" style="width: 62%;
						color: #dadada;
						font-size: 12px;
						text-align: center;
						padding-left: 10px;">
						<div>{{$t('详细地址')}}：{{$t('隐藏，请联系客服设为可见')}}</div>
						<div>{{$t('联系方式')}}：{{$t('隐藏，请联系客服设为可见')}}</div>
					</div>
				</div>
			</div>
			
			
			<div class="border kuangkuang mt10">
				<div class="ad flex-row score_">
					<div class="flex-column left" style="width: 100%;align-items: baseline;gap: 1vw;padding:6px;">
						<div class="fulipinpai white"><van-icon name="orders-o" color="#c1986e" size="0.8rem" />{{$t('基本信息')}}：{{this.xuanfeidata.nianling}}{{$t('岁')}} {{$t('身高')}}：{{this.xuanfeidata.shengao}}</div>
						<div class="fulipinpai white"><van-icon name="orders-o" color="#c1986e" size="0.8rem"/>{{$t('地区')}}：{{$t('未定义')}}</div>
						<div class="fulipinpai white"><van-icon name="orders-o" color="#c1986e" size="0.8rem"/>{{$t('可以激活服务项目')}}</div>
						<div class="line" style="width:100%;height:4px; border-bottom: 1px solid #7e6a56;line-height: 2.3vw;"></div>
						<div class="fulipinpai white">{{xuanfeidata.summery}}</div>
					</div>
				</div>
			</div>
			
			
			
			<div class="box">
				<van-image width="100%" fit="contain" height="100%" v-for="(v, k) in xuanfeidata.img_url" :key="k" :src="v" />
				<van-button round="true" @click="yuyue" class="yuyue">{{$t('立即邀请她')}}</van-button>
			</div>
		</div>
		
	</div>
</template>

<script>
export default {
	data() {
		return {
			xuanfeidata: []
		};
	},
	methods: {
		back() {
			this.$router.push({ path: '/Choose' });
			// this.$router.push({ path: 'list?id=' + this.$route.query.adsid + '&name=' + this.$route.query.name });
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.xuanfeidata = res.data;
				
				console.log("xuanfeidata", this.xuanfeidata)
			});
		},
		yuyue() {
			
			if(this.xuanfeidata.lianxi_url == ''){
				this.$toast(this.$t("请联系顾问或接待员"));
			}else{
				window.location.href = this.xuanfeidata.lianxi_url;
			}
			
		}
	},
	created() {
		this.getxuanfeidata();
	}
};
</script>

<style>
.yuyue{
	margin: 5vw auto;
	width: 70%;
	height: 7vw;
	line-height: 7vw;
	background: linear-gradient(#fde3ca, #e7b486);
	text-align: center;
	color: #333;
	border-radius: 2.5vw;
	font-size: 1.2rem;
}
.mt10{
	margin-top: 2vw;
}
.score_ {
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid #7e6a56;
    display: flex;
    align-items: center;
    padding: 10px;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
}
.hang{
	line-height: 7vw;
}
.white{
	color: white;
}
.huang{
	color: #edbd71;
	font-size: 0.8rem;
}
.btitle{
	font-weight: 1000;
	background-image: -webkit-linear-gradient(top, #f8dbae, #edbd71);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 1.1rem;
	text-align: center;
	margin: 0px 10px 0 10px;
}
.fulipinpai{
	color: #ddb285;
	font-size: 0.8rem;
	text-align: center;
	.van-icon{
		width: 6vw;
		height: 5vw;
		text-align: center;
	}
}

.mm{
	padding: 2.5vw 2vw;
	background-color: #212121;
}
.mm .notice-swipe .van-icon{
	width: 10vw;
	height: 9vw;
	text-align: center;
	line-height: 9vw;
}
.mm .notice-swipe .van-icon:before{
	width: 9vw;
	height: 8vw;
	font-size: 1.3rem;
}
.notice-swipe {
	height: 10vw;
	line-height: 10vw;
	padding: 0px !important;
}
.van-notice-bar__content{
	font-size: 0.9rem;
}
.my-swipe .van-swipe-item {
	color: #fff;
	font-size: 20px;
	line-height: 150px;
	text-align: center;
	background-color: #39a9ed;
	width: 100% !important;
}

.container {
	display: inline-block;
}
.box {
	width: 100%;
	margin: 0 auto;
	text-align: center;
}
.name {
	font-size: 1.125rem;
}
.title {
	font-size: 0.625rem;
}
.button {
	width: 10rem;
	height: 2.5rem;
	font-size: 0.9375rem;
	margin-top: 0.625rem;
}
</style>
